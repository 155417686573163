import React, {useEffect, useState} from 'react';
import './App.css';
import {BeatLoader} from "react-spinners";
import AppLayout from "./layout/AppLayout";
import {useKeycloak} from "@react-keycloak/web";

function App() {
    const { keycloak, initialized } = useKeycloak();

    useEffect(() => {
        if(initialized && !keycloak.authenticated) {
            keycloak.login()
        }
    }, [initialized])

    return (
    <div className="App">
      <div className="AppWrapper">

                {!keycloak.authenticated &&  <div className="SpinnerContainer"><img src={require('./img/ekuria-logo.png')} alt="Logo e-kuria" /><BeatLoader color="#0077C9"/></div>}
                {keycloak.authenticated && <AppLayout/>}

      </div>
    </div>
    );
}

export default App;
