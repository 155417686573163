import AppHeader from "./AppHeader";
import './AppLayout.scss';
import AppContent from "./AppContent";
import {ProfileContext, initialProfile} from "../context/ProfileContext";
import {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {KeycloakProfile} from "keycloak-js";
import {BrowserRouter} from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

function AppLayout() {

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setCookie("clause1", "1", 30)
        setOpen(false);
    };

    const [profile, setProfile] = useState(initialProfile);
    const {keycloak, initialized} = useKeycloak();
    useEffect(() => {
        if(initialized && keycloak.authenticated) {
            keycloak.loadUserProfile().then(p => {
                setProfile(p);
                // console.log(keycloak.tokenParsed)
            })
        }
    }, [initialized])

    function setCookie(cname: string, cvalue: string, exdays: number) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname: string) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    return (
        <ProfileContext.Provider value={profile}>
            <div className="AppLayout">
                {getCookie("clause1") !== "1" &&
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Regulamin serwisu
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <p>Strona internetowa ekuria.pl korzysta z plików cookies. Korzystając z serwisu, akceptujesz <a href="/polityka_prywatnosci.pdf" target="_blank">politykę prywatności</a>.</p>
                                <p>Korzystając z serwisu akceptujesz <a href="/regulamin.pdf" target="_blank">regulamin</a>.</p>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained">
                                Akceptuję
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                <BrowserRouter>
                    <AppHeader/>
                    <AppContent/>
                </BrowserRouter>
            </div>
        </ProfileContext.Provider>
    )
}

export default AppLayout;