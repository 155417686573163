import {Button, Grid, TextField, Typography} from "@mui/material";
import './ChangePasswordForm.scss'
import {useKeycloak} from "@react-keycloak/web";

function ChangePasswordForm() {

    const {keycloak, initialized} = useKeycloak();

    // const fa

    return (
        <Grid container className="ChangePasswordWrapper">

            <Grid item xs={1} md={3}></Grid>
            <Grid item xs={10} md={6}>
                <Typography variant="h4">
                    Zmiana hasła
                </Typography>
                <TextField
                    fullWidth
                    label="Stare hasło"
                    type="Password"
                    variant="outlined" />
                <TextField
                    fullWidth
                    label="Nowe hasło"
                    type="Password"
                    variant="outlined" />
                <TextField
                    fullWidth
                    label="Powtórz nowe hasło"
                    type="Password"
                    variant="outlined" />
                <Button variant="contained" size="large" fullWidth>
                    Zapisz
                </Button>
            </Grid>
        </Grid>
    );
}

export default ChangePasswordForm;