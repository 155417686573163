import {Grid, Typography} from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppHeaderButton from "./AppHeaderButton";
import {useKeycloak} from "@react-keycloak/web";
import React, {useContext} from "react";
import {ProfileContext} from "../context/ProfileContext";
import {Link} from "react-router-dom";

function AppHeader() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const keycloak = useKeycloak();
    const profile = useContext(ProfileContext);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container className="AppHeader">
            <Grid xs={12} md={2} className="AppHeaderLogoWrapper">
                <Link to="/"><img src={require('../img/ekuria-logo.png')} alt="Logo e-kuria"/></Link>
            </Grid>
            <Grid xs={12} md={8} className="AppHeaderTitle">
                <Typography variant="h5">{profile?.attributes['displayed_name']}</Typography>
                <Typography>{profile?.attributes['displayed_address']}</Typography>
            </Grid>
            <Grid xs={12} md={2} className="AppHeaderButtonWrapper">
                <AppHeaderButton
                    name="Ustawienia"
                    icon={require('./icons/ustawienia.png')}
                    action={handleClick}
                />
                <Menu
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                >
                    <MenuItem onClick={e =>{
                        keycloak.keycloak.login({action: "UPDATE_PASSWORD"})
                    }}>Zmiana hasła</MenuItem>
                </Menu>
                <AppHeaderButton
                    name="Wyloguj"
                    icon={require('./icons/wyloguj.png')}
                    action={() => keycloak.keycloak.logout()}
                />
            </Grid>
        </Grid>
    )
}

export default AppHeader;