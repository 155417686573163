import ServicesGrid from "../services/ServicesGrid";
import {BrowserRouter, Route, Router, Routes} from "react-router-dom";
import ChangePasswordForm from "../password/ChangePasswordForm";

function AppContent() {
    return (
           <Routes>
               <Route path="/">
                   <Route index element={<ServicesGrid/>}/>
                   <Route path="changePassword" element={<ChangePasswordForm/>}/>
               </Route>
           </Routes>
    )
}

export default AppContent;