import {Grid, Typography} from "@mui/material";
import {EventHandler} from "react";

function ServicesGridElement(props: {name: string, isActive: boolean, icon: string, action: EventHandler<any>}) {
    let classNames = props.isActive ? "ServicesGridElement" : "ServicesGridElement ServicesGridElementDisabled"

    return (
        <Grid md={2} xs={12} className="ServicesGridElementWrapper" onClick={props.isActive ? props.action : (e) => {}}>
            <div className={classNames}>
                <div className="ServicesGridElementIconwrapper">
                    <img src={props.isActive ? props.icon : require('./icons/icon-nieaktywne.png')}/>
                </div>
                <Typography>{props.name}</Typography>
            </div>
        </Grid>
    );
}

export default ServicesGridElement;