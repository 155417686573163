import {Grid, Typography} from "@mui/material";
import './ServicesGrid.scss';
import ServicesGridElement from "./ServicesGridElement";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {ProfileContext} from "../context/ProfileContext";
import {useKeycloak} from "@react-keycloak/web";

function ServicesGrid() {
    const navigate = useNavigate()
    const profile = useContext(ProfileContext);
    const {keycloak, initialized} = useKeycloak();

    return (
        <Grid container className="ServicesGrid" alignItems="center" justifyContent="center">
            <ServicesGridElement
                name="Aktualne dane parafii"
                isActive={false}
                icon={require('./icons/icon-dane-parafii.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="Baza danych ochrzczonych"
                isActive={false}
                icon={require('./icons/icon-ochrzczeni.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="Baza danych parafii"
                isActive={false}
                icon={require('./icons/icon-baza-parafii.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="E-Celebret"
                isActive={false}
                icon={require('./icons/icon-celebret.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="E-pismo"
                isActive={true}
                icon={require('./icons/epismo 1.png')}
                action={() => window.location.href = "https://epismo.ekuria.pl"}
                />
            <ServicesGridElement
                name="E-Finanse"
                isActive={false}
                icon={require('./icons/icon-dane-parafii.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="Kanclerz"
                isActive={false}
                icon={require('./icons/icon-dane-parafii.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="Parafio - intencje i darowizny online"
                isActive={true}
                icon={require('./icons/parafio.png')}
                action={() => window.location.href = "https://admin.parafio.com/user/login/ekuria?utm_source=ekuria&utm_medium=dashboard_button&utm_campaign=none"}
                />
            <ServicesGridElement
                name="FARA - opropgramowanie dla parafii"
                isActive={true}
                icon={require('./icons/fara.png')}
                action={() => window.location.href = "https://fara.pl/"}
                />
            <ServicesGridElement
                name="Rekolekcje dla osób duchownych"
                isActive={false}
                icon={require('./icons/icon-rekolekcje.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="Sąd kurialny"
                isActive={false}
                icon={require('./icons/icon-dane-parafii.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="Schematyzm katolicki"
                isActive={true}
                icon={require('./icons/icon-dane-parafii.png')}
                action={() => window.open('https://schematyzm.katolicki.pl', '_blank')}
                />
            <ServicesGridElement
                name="System ankiet katechetycznych"
                isActive={keycloak.tokenParsed?.realm_access?.roles.includes("isak_user") === true}
                icon={require('./icons/icon-ankiety.png')}
                action={() => window.open('https://e-kuria.pl', '_blank')}
                />
            <ServicesGridElement
                name="Wydział duszpasterski"
                isActive={false}
                icon={require('./icons/icon-dane-parafii.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="Zdrowie"
                isActive={false}
                icon={require('./icons/icon-zdrowie.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="Zgłoszenia"
                isActive={false}
                icon={require('./icons/icon-dane-parafii.png')}
                action={() => alert('ok')}
                />
            <ServicesGridElement
                name="Księgi pamiątkowe"
                isActive={true}
                icon={require('./icons/memento.png')}
                action={() => window.open('https://sp.katolicki.pl', '_blank')}
                />
        </Grid>
    )
}

export default ServicesGrid;