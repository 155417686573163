import React from "react";
import {KeycloakProfile} from "keycloak-js";

export interface EkuriaKeycloakProfile extends KeycloakProfile {
    attributes?: any
}

export const initialProfile : EkuriaKeycloakProfile = {
    username: "trwa ładowanie..",
    attributes: {
        displayed_name: "Trwa ładowanie",
        displayed_address: "Trwa ładowanie"
    }
}

export const ProfileContext = React.createContext(initialProfile);
